<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleDownload" icon="el-icon-download" type="defalut" v-has="'batch_hi_download'">导出</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康干预"/>
        </div>
<!--        <el-row style="height: 100%;display: flex;">-->
<!--            <el-col :span="19" class="page">-->
                <div class="search">
                    <el-form :inline="true" size="small" label-width="70px">
<!--                        <el-form-item label="工作区">-->
<!--                            <el-select v-model="search.wpName" placeholder="请选择" style="width:100%" clearable>-->
<!--                                <el-option label="全部" value="全部"></el-option>-->
<!--                                <el-option :label="item.name" :value="item.name" v-for="(item,index) in gzqList"-->
<!--                                           :key="index"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="工作单位" label-width="70px">
                            <cascader-company-name v-model="search.companyName"></cascader-company-name>
                        </el-form-item>
                        <el-form-item label="体检报告">
                            <el-date-picker v-model="search.reportTime" type="daterange" range-separator="至"
                                            start-placeholder="开始日期"
                                            value-format="yyyy-MM-dd" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="方案进行">
                            <el-select v-model="search.isFa" placeholder="请选择">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="无方案" value="2"></el-option>
                                <el-option label="有方案" value="3"></el-option>
                            </el-select>
                        </el-form-item>
<!--                        <el-form-item label="执行医生">-->
<!--                            <el-select v-model="search.doctor" placeholder="请选择">-->
<!--                                <el-option label="全部" value="all"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="回填状态">
                            <el-select v-model="search.backfill_status" placeholder="请选择" style="width:100%" clearable>
                                <el-option label="已发送未填写" value="1"></el-option>
                                <el-option label="已填写" value="2"></el-option>
                            </el-select>
                        </el-form-item>
<!--                        <el-form-item label="标签分组">-->
<!--                            <el-select v-model="search.KHBQ" placeholder="请选择" style="width:100%" clearable>-->
<!--                                <el-option label="全部" value="全部"></el-option>-->
<!--                                <el-option :label="item.name" :value="item.name" v-for="(item,index) in tagList"-->
<!--                                           :key="index"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="方案日期">
                            <el-date-picker v-model="search.fanganTime" type="daterange" range-separator="至"
                                            start-placeholder="开始日期" style="width: 230px"
                                            value-format="yyyy-MM-dd" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="搜索" label-width="40px">
                            <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                                <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                                    <el-option label="名称" value="name"></el-option>
                                    <el-option label="身份证" value="idCard"></el-option>
                                    <el-option label="手机号" value="mobile"></el-option>
                                    <el-option label="单位" value="companyName"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="风险人群" label-width="">
                            <el-select v-model="search.risklevel" placeholder="请选择" style="width:150px" clearable>
                                <el-option label="健康人群" value="健康人群"></el-option>
                                <el-option label="风险人群" value="风险人群"></el-option>
                                <el-option label="异常人群" value="异常人群"></el-option>
                                <el-option label="慢病人群" value="慢病人群"></el-option>
                                <el-option label="大病人群" value="大病人群"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="人群等级" label-width="70px">
                            <el-select v-model="search.level" placeholder="请选择" style="width:100%" filterable allow-create
                                       default-first-option clearable>
                                <el-option label="全部" value="全部"></el-option>
                                <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                                           :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="指标等级" label-width="">
                            <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                                         v-model="search.risklevelList"></el-cascader>
                        </el-form-item>
                        <el-form-item label="评估状态" label-width="70px">
                            <el-select v-model="search.status" placeholder="请选择" style="width:140px" clearable>
                                <el-option label="已完成" value="2"></el-option>
                                <el-option label="未评估" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData" v-has="'task_search'">查询</el-button>
                            <el-button type="" plain @click="reset" v-has="'task_search'">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="search" style="background-color: #fff">
                    <div class="btns">
                        <el-button type="success" size="mini" @click="handleAdd" :disabled="idList.length?false:true" v-has="'task_zdgyfa'">批量制定干预方案</el-button>
                    </div>
                </div>
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" stripe size="mini" v-loading="loading"
                                  @selection-change="handleSelectionChange"
                                  style="width: 100%;cursor: pointer" height="100%"
                                  border >
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="55"/>
                            <el-table-column align="center" label="基本信息" width="">
                                <template slot-scope="scope">
                                    <div>
                                        <span>{{ scope.row.name }}</span>
                                        <span v-if="scope.row.sex == '2'"> 女 </span>
                                        <span v-if="scope.row.sex == '1'"> 男 </span>
                                        <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                                    </div>
                                    <div>
                                        <small > {{ isFullIdCard[scope.$index] ? scope.row.idCard : maskIdCardRegex(scope.row.idCard) }}</small>
                                        <i class="el-icon-view" style="cursor: pointer;padding-left: 10px" @click="toggleIdCard(scope.$index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="客户标签" width="120">
                                <template slot-scope="scope">
                                    <popover-JCXM :tagList="scope.row" />
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="联系方式" width="">
                                <template slot-scope="scope">
                                    <div>
                                        <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                        <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                        <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                        <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                                    </div>
                                    <div>{{ scope.row.mobile }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="方案名称" width="120" prop="fname" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column align="center" label="方案状态" width="120" prop="fstatus">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.fstatus === 1" type="danger">待执行</el-tag>
                                    <el-tag v-else-if="scope.row.fstatus === 2" type="warning">进行中</el-tag>
                                    <el-tag v-else-if="scope.row.fstatus === 3" type="success">已完成</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="执行医生" width="120" prop="fdoctor">
                            </el-table-column>
                            <el-table-column align="center" label="历史方案" width="160" prop="programCount">
                            </el-table-column>
                            <el-table-column align="center" width="200" label="操作" fixed="right">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="handleInfo(scope.row)" v-has="'task_ckfa'">
                                        查看方案
                                    </el-button>
                                    <el-button type="text" size="mini" @click="handleJianKang(scope.row)" v-has="'task_da'">
                                        健康档案
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
<!--            </el-col>-->
<!--            <el-col :span="5">-->
<!--                <div style="padding-left: 20px;" class="right-search">-->
<!--                    <el-form :model="search" size="small" label-position="top">-->
<!--                        <el-form-item label="标签筛选">-->
<!--                            <el-radio-group v-model="search.tag" @change="handelRadio">-->
<!--                                <el-radio label="1">体检</el-radio>-->
<!--                                <el-radio label="2">评估</el-radio>-->
<!--                                <el-radio label="3">个人</el-radio>-->
<!--                            </el-radio-group>-->
<!--                        </el-form-item>-->
<!--                        <div v-if="search.tag=='1'">-->
<!--                            <el-form-item label="体检时间">-->
<!--                                <el-date-picker-->
<!--                                        style="width: 100%;"-->
<!--                                        v-model="search.time"-->
<!--                                        type="daterange"-->
<!--                                        range-separator="至"-->
<!--                                        start-placeholder="开始日期"-->
<!--                                        end-placeholder="结束日期">-->
<!--                                </el-date-picker>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.kjz" placeholder="请输入关键字过滤"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </div>-->
<!--                        <div v-if="search.tag=='2'">-->
<!--                            <el-form-item label="">-->
<!--                                <el-select v-model="search.type" placeholder="" style="width:100%">-->
<!--                                    <el-option label="全部" value="0"></el-option>-->
<!--                                    <el-option label="HRA健康风险评估3.0" value="1"></el-option>-->
<!--                                    <el-option label="慢病健康风险评估" value="2"></el-option>-->
<!--                                    <el-option label="HRA健康风险评估2.0" value="3"></el-option>-->
<!--                                    <el-option label="心理健康评估" value="4"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.kjz" placeholder="请输入关键字过滤"-->
<!--                                          type="textarea"-->
<!--                                          maxlength="5"-->
<!--                                          show-word-limit-->
<!--                                ></el-input>-->
<!--                            </el-form-item>-->
<!--                            <el-tree style="height: calc( 100vh - 350px );overflow: auto;"-->
<!--                                     ref="tree"-->
<!--                                     :data="pgList"-->
<!--                                     @check="handelNode"-->
<!--                                     show-checkbox-->
<!--                                     node-key="id"-->
<!--                                     :props="defaultProps">-->
<!--                            </el-tree>-->
<!--                        </div>-->
<!--                        <div v-if="search.tag=='3'">-->
<!--                            <el-form-item label="">-->
<!--                                <el-input v-model="search.grtag" placeholder="请输入关键字过滤" type="textarea"-->
<!--                                          maxlength="5"-->
<!--                                          show-word-limit></el-input>-->
<!--                            </el-form-item>-->
<!--                            <el-checkbox-group v-model="search.grTagList" @change="getData"-->
<!--                                               style="height: calc( 100vh - 300px );overflow: auto;">-->
<!--                                <div v-for="(item,index) in grList" :key="index" style="padding: 5px">-->
<!--                                    <el-checkbox :label="item.name"></el-checkbox>-->
<!--                                </div>-->
<!--                            </el-checkbox-group>-->
<!--                        </div>-->
<!--                    </el-form>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
        <data-form :visible="dialogVisible" @close="dialogClose" :localForm="form" @update="getData"
                   :idList="idList"></data-form>
        <el-dialog
                :visible.sync="dialogVisibleExport"
                title="提示"
                width="30%"
        >
            <p>导出的数据不能超过2万条，请缩小查询范围或导出当前范围内的前2万条数据。</p>
            <p>导出时请不要关闭页面。</p>
            <el-progress :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleExport = false" :disabled="but_disabled" v-loading="but_loading">取消</el-button>
                <el-button type="primary" @click="exportLimitedData" :disabled="but_disabled" v-loading="but_loading">导 出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import data from './data'
    import DataForm from './widgets/dataForm'
    import {dbList, dwList, grList, pgList} from "../../data/utlisData";

    const makeInitSearch = () => {
        return {
            gzq: "全部",
            reportTime: [],
            status: "",
            fanganTime: [],
            companyName:"",
            company: "",
            doctor: "",
            tagGroup: "全部",
            key: "name",
            value: "",
            report: "全部",
            tag:"1",
            grTagList: [],
            evalTags:[],
            type: "0",
            risklevelList: [],
            level: "",
            risklevel: ""
        }
    }

    export default {
        name: 'hi',
        components: {
            DataForm
        },
        data() {
            return {
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value'
                },
                isFullIdCard: {},
                infoVisible: false,
                tagList: [
                    {
                        id: 3,
                        name: "三级"
                    },
                    {
                        id: 2,
                        name: "二级"
                    },
                    {
                        id: 1,
                        name: "一级"
                    },
                ],
                dialogVisible: false,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                form: {
                    plans: [],
                    healthGuidance: {}
                },
                data: [],
                idList:[],
                dialogVisibleExport: false,
                progress: 0, // 进度条的百分比
                but_loading: false,
                but_disabled: false,
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(from.path.indexOf("/main/phr/detail/")>-1){
                    vm.handlePopState()
                }
            });
        },
        methods: {
            async handleDownload() {
                this.progress = 0; // 初始化进度
                this.dialogVisibleExport = true; // 显示进度条对话框
                // 模拟导出数据的过程
            },
            async exportLimitedData() {
                try {
                    this.but_loading = true
                    this.but_disabled = true
                    this.$http({
                        url: `/hi/export`,
                        method: 'POST',
                        responseType: 'blob', // 重要：确保响应类型为blob
                        data: this.search,
                        headers: {},
                        onDownloadProgress:(progressEvent)=>{
                            let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                            //接收进度为99%的时候需要留一点前端编译的时间
                            if(progressBar >= 99){
                                this.progress = 99;
                            }else{
                                this.progress = progressBar;
                            }
                        }
                    }).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName = 'default.xlsx';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                            if (fileNameMatch && fileNameMatch.length === 2) {
                                fileName = decodeURIComponent(fileNameMatch[1]);
                            }
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url); // 清理
                        this.but_loading = false
                        this.but_disabled = false
                        this.dialogVisibleExport = false; // 隐藏导出进度对话框
                        this.$message.success('数据导出完成！');
                    }).catch((error) => {
                        this.but_loading = false
                        this.but_disabled = false
                        this.$message({
                            message: error,
                            type: 'error'
                        });
                    });
                } catch (error) {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message({
                        message: error,
                        type: 'error'
                    });
                }
            },
            handlePopState() {
                const savedConditions = localStorage.getItem('searchConditions');
                const black = localStorage.getItem('back');
                if (savedConditions && black) {
                    this.search = JSON.parse(savedConditions);
                }
            },
            handelRadio(){
                this.search.evalTags = []
                this.search.grTagList = []
            },
            handelNode() {
                var tagList = []
                this.$refs.tree.getCheckedNodes().forEach(function (item) {
                    tagList.push(item.name)
                })
                this.search.evalTags = tagList
                this.getData()
            },
            maskIdCardRegex(idCard) {
                if(!idCard) return ""
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleSelectionChange(val) {
                var that = this
                if (val.length) {
                    val.forEach(function (item) {
                        that.idList.push({id:item.id,programCount:item.programCount,workspaceName:item.wpName
                        })
                    })
                } else {
                    that.idList = []
                }
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                    localStorage.removeItem('searchConditions');
                    localStorage.removeItem('back');
                }, 0)
            },
            async handleInfo(row) {
                var that = this
                that.form = row
                if(!row.bid){
                    this.$message.info("没有待执行和进行中的方案！")
                    return
                }
                const resp = await this.$http.get(`/hi/view/${row.bid}`)
                if (!resp.data.data) {
                    that.$message.error(resp.data.msg)
                } else {
                    let plans = resp.data.data.plans || []
                    for (let i = 0; i < plans.length ; i++) {
                        if(plans[i].interveneModus == "3"){
                            plans[i].content = JSON.parse(plans[i].content)
                        }
                    }
                    that.form = resp.data.data
                    that.form.plans = plans
                    that.dialogVisible = true
                }
            },
            handleJianKang(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            handleAdd() {
                this.form = {
                    plans: [],
                    healthGuidance: {},
                    image:[],
                    video:[],
                    supplementContent:{}
                }
                this.dialogVisible = true
            },
            dialogClose() {
                this.dialogVisible = false
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/hi`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                    localStorage.setItem('searchConditions',JSON.stringify(this.search));
                }
                this.loading = false
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            }
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
    ::v-deep .el-form--inline .el-form-item{
        margin: 5px;
    }
    ::v-deep .right-search .el-form-item--small .el-form-item__content, ::v-deep .right-search .el-form-item--small .el-form-item__label {
        line-height: 15px !important;
    }
</style>
